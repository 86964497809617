import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Navbar from "../components/navbar"
import { Footer } from "../components/footer"

class NotFoundPage extends React.Component {
  render() {

	return (
		<>
		  <SEO title="404: Not Found" />
		  <Navbar />
		  <section className="section-wrapper section-wrapper-404 bg-blue-dark">
		  	<div className="section section-404 column-direction">
				<h1>Ooops, page not found</h1>
				<p>You just hit a route that doesn&#39;t exist... </p>
				<div class="frame">
					<div class="ghost-container">
						<div class="upperbody">
						<div class="face">
							<div class="eyes-wrapper">
							<div class="eye eye-1"></div>
							<div class="eye eye-2"></div>
							</div>
							<div class="cheeks-wrapper">
							<div class="cheek"></div>
							<div class="cheek"></div>
							</div>
							<div class="mouth-wrapper">
							<div class="mouth"></div>
							</div>
						</div>
						</div>
						<div class="lowerbody-container">
						<div class="lowerbody lowerbody-1"></div>
						<div class="lowerbody lowerbody-2"></div>
						<div class="lowerbody lowerbody-3"></div>
						<div class="lowerbody lowerbody-4"></div>
						</div>
					</div>
				</div>
			</div>
		  </section>
		  <Footer />
		  </>
	  )
    
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
